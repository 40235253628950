import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  root: {
    zIndex: 1,
    width: '100%',
    minHeight: unit * 4,
    backgroundColor: palette.paper,
    position: 'relative',
  },
  button: {
    position: 'absolute',
    right: unit / 3,
    top: unit / 3,
    zIndex: 300,
  },
  nakedButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderRadius: '0 0 0 50%',
  },
  content: {
    padding: unit * 1.25,
    paddingRight: unit * 3,
    [breakpoints.up('sm')]: {
      padding: unit * 2,
      paddingRight: unit * 5,
    },
  },
})

const PopupHeader = props => {
  const {
    classes,
    className,
    style,
    contentClassName,
    contentStyles,
    hideCloseButton,
    onClose,
    hideTitle,
    children,
  } = props

  if (hideTitle) {
    return (
      !hideCloseButton && (
        <IconButton className={classNames(classes.button, classes.nakedButton)} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      )
    )
  }

  return (
    <div className={classNames(classes.root, className)} style={style}>
      {children && (
        <div className={classNames(classes.content, contentClassName)} style={contentStyles}>
          {children}
        </div>
      )}
      {!hideCloseButton && (
        <IconButton className={classes.button} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      )}
    </div>
  )
}

PopupHeader.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  style: PropTypes.object,
}

export default withStyles(styles)(PopupHeader)

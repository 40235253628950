import React from 'react'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { createPalette } from './colorPalettes'
import { fade } from '@material-ui/core/styles/colorManipulator'

const UNIT = 12
let direction = 'ltr'

try {
  direction = document.body.getAttribute('dir')
} catch (err) {
  console.log(`'Failed to get attribute 'dir':`, err)
}

const fontMap = {
  'open-sans': 'Open Sans',
  roboto: 'Roboto',
  cambay: 'Cambay',
  cairo: 'Cairo',
  'noto-sans-arabic': 'Noto Sans Arabic',
  kaiti: 'KaiTi',
  heiti: 'HeiTi',
  bayon: 'Bayon',
  kantumruy: 'Kantumruy',
  padauk: 'Padauk',
  'noto-sans-myanmar': 'Noto Sans Myanmar',
}

export const getFontFamily = fontClass => {
  const fallbackFont = 'Roboto, sans-serif'
  const fontFamily = fontMap[fontClass] || 'Roboto'
  return `${fontFamily}, ${fallbackFont}`
}

let breakpoints = createBreakpoints({})

export const createTheme = ({ direction = 'ltr', fontName = 'roboto' }) => {
  const palette = createPalette()
  return {
    palette,
    direction,
    typography: {
      useNextVariants: true,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: getFontFamily(fontName),
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
        letterSpacing: '0.016rem',
        color: palette.text.primary,
        [breakpoints.down('xs')]: {
          lineHeight: 1.4,
        },
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.75,
        letterSpacing: '0.016rem',
        color: palette.text.secondary,
        [breakpoints.down('xs')]: {
          lineHeight: 1.4,
        },
      },
      h6: {
        fontSize: '1.25rem',
        fontWeight: 'normal',
        lineHeight: 1.2,
        letterSpacing: '0.025rem',
        color: palette.text.primary,
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
          fontSize: '1rem',
          letterSpacing: '0.016rem',
        },
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        lineHeight: 1.4,
        letterSpacing: '0.313rem',
        wordBreak: 'break-word',
        textTransform: 'uppercase',
        [breakpoints.down('xs')]: {
          fontSize: '0.95rem',
          letterSpacing: '0.063rem',
          lineHeight: 1.2,
        },
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.375rem',
        lineHeight: 1.33,
        color: palette.text.primary,
        textTransform: 'uppercase',
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
          fontSize: '1.125rem',
          letterSpacing: '0.063rem',
          lineHeight: 1.2,
        },
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 'bold',
        color: palette.text.primary,
        lineHeight: 1.25,
        letterSpacing: '0.5rem',
        textTransform: 'uppercase',
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
          fontSize: '1.5rem',
          letterSpacing: '0.063rem',
          lineHeight: 1.2,
        },
      },
      h2: {
        fontWeight: 'bold',
        fontSize: '2.5rem',
        letterSpacing: '0.625rem',
        lineHeight: 1.2,
        textTransform: 'uppercase',
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
          fontSize: '1.875rem',
          letterSpacing: '0.094rem',
          lineHeight: 1.1,
        },
      },
      h1: {
        fontWeight: 'bold',
        fontSize: '3.37rem',
        letterSpacing: '0.844rem',
        lineHeight: 1.19,
        textTransform: 'uppercase',
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
          fontSize: '2.5rem',
          letterSpacing: '0.125rem',
          lineHeight: 1.1,
        },
      },
      button: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
        letterSpacing: '0.088rem',
        textAlign: 'center',
        textTransform: 'none',
        color: palette.text.primary,
        [breakpoints.down('xs')]: {
          lineHeight: 1.2,
          letterSpacing: '0.088rem',
        },
      },
      caption: {
        fontSize: '0.75rem',
        lineHeight: 1.33,
        letterSpacing: '0.025rem',
        color: palette.text.primary,
      },
      subtitle1: {
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.016rem',
        color: palette.text.secondary,
        [breakpoints.down('xs')]: {
          lineHeight: 1.3,
        },
      },
      subtitle2: {
        fontSize: '0.875rem',
        lineHeight: 1.71,
        letterSpacing: '0.016rem',
        [breakpoints.down('xs')]: {
          lineHeight: 1.3,
        },
      },
      overline: {
        fontSize: 10,
        lineHeight: 1.6,
        letterSpacing: '0.094rem',
      },
      numeric: {
        letterSpacing: 'normal',
      },
    },
    spacing: {
      unit: UNIT,
    },
    props: {
      MuiButtonBase: { disableRipple: true },
      MuiSwitch: { color: 'primary' },
      MuiLinearProgress: { color: 'primary' },
    },
    overrides: {
      MuiAppBar: {
        root: {
          boxShadow: 'none',
          padding: 0,
          paddingLeft: UNIT * 4,
        },
      },
      MuiStepConnector: {
        alternativeLabel: {
          top: 19,
        },
      },
      MuiStepper: {
        root: {
          paddingTop: 0,
          paddingBottom: UNIT,
        },
      },
      MuiChip: {
        root: {
          justifyContent: 'flex-start',
        },
      },
      MuiPaper: {
        root: {
          // border: `1px solid ${palette.grey100}`,
          backgroundColor: palette.paper,
        },
        rounded: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: palette.grey200,
        },
      },
      MuiInput: {
        input: {
          fontSize: '1.0625rem',
          letterSpacing: 0.2,
          color: palette.grey500,
        },
        inputType: {
          height: 'inherit',
        },
        underline: {
          '&:before': {
            borderBottomColor: palette.grey500,
          },
          '&:after': {
            borderBottomColor: palette.accent400.main,
          },
        },
      },
      MuiSelect: {
        select: {
          fontSize: 'inherit',
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: '1.0625rem',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: '1.41em',
          letterSpacing: 0.2,
          '&$focused': {
            color: palette.accent400.main,
          },
        },
        focused: {
          '&$focused': {
            color: palette.accent400.main,
          },
        },
      },
      MuiListItemIcon: {
        root: {
          marginRight: UNIT,
          color: palette.grey500,
        },
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: palette.accent100.main,
          },
        },
      },
      MuiList: {
        root: {
          border: `1px solid ${palette.grey100}`,
        },
      },
      MuiStepIcon: {
        root: {
          fontSize: '2.5rem',
        },
        active: {
          color: palette.grey500 + ' !important',
        },
        completed: {
          color: palette.common.green + ' !important',
        },
        text: {
          fill: palette.paper,
        },
      },
      MuiMenuItem: {
        root: {
          paddingLeft: UNIT * 2,
        },
        selected: {
          color: palette.accent200.contrastText,
          backgroundColor: palette.accent200.main + ' !important',
        },
      },
      MuiButton: {
        root: {
          color: 'inherit',
          backgroundColor: 'transparent',
          '&:hover': {
            color: 'inherit',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
          '&:focus': {
            textDecoration: 'underline',
            color: 'inherit',
            backgroundColor: 'transparent',
          },
          '&:active': {
            '& $label:after': {
              backgroundColor: 'currentColor',
              opacity: 0.1,
              position: 'absolute',
              content: '""',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
            color: 'inherit',
          },
          '&$disabled': {
            color: 'inherit',
            opacity: 0.5,
          },
        },
        outlined: {
          color: palette.grey500,
          border: `1px solid ${palette.grey500}`,
          backgroundColor: 'transparent',
          borderRadius: 0,
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
            boxShadow: `0 0 0 1px ${palette.grey500}`,
          },
          '&:focus': {
            overflow: 'hidden',
            backgroundColor: 'transparent',
            textDecoration: 'none',
            boxShadow: `0 0 0 1px ${palette.grey500}`,
          },
          '&:active': {
            '& $label': {
              backgroundColor: 'transparent !important',
            },
            backgroundColor: fade(palette.grey500, 0.1),
            color: palette.grey500,
          },
          '&$disabled': {
            border: `1px solid ${palette.grey400}`,
            color: palette.grey400,
          },
        },
        outlinedPrimary: {
          borderColor: palette.accent400.main,
          '&:hover': {
            borderColor: palette.accent400.main,
          },
          '&$disabled': {
            borderColor: palette.accent200.main,
          },
        },
        outlinedSecondary: {
          color: palette.grey500,
          borderColor: palette.grey500,
          '&:hover': {
            borderColor: palette.grey500,
            backgroundColor: palette.grey200,
          },
          '&:focus': {
            color: palette.grey500,
            borderColor: palette.grey500,
            backgroundColor: palette.grey200,
          },
          '&$disabled': {
            color: palette.grey200,
            borderColor: palette.grey200,
          },
        },
        contained: {
          boxShadow: 'none',
          borderRadius: 0,
          textTransform: 'uppercase',
          backgroundColor: palette.accent400.main,
          borderColor: palette.accent400.main,
          color: palette.accent400.contrastText,
          '&:hover': {
            backgroundColor: palette.accent300.main,
            color: palette.accent400.contrastText,
            textDecoration: 'none',
          },
          '&:focus': {
            backgroundColor: palette.accent300.main,
            color: palette.accent400.contrastText,
            textDecoration: 'none',
            boxShadow: `0 0 0 2px ${palette.accent500.main}`,
          },
          '&:active': {
            '& $label': {
              backgroundColor: 'transparent !important',
            },
            backgroundColor: palette.accent500.main,
            color: palette.accent400.contrastText,
          },
          '&$disabled': {
            backgroundColor: palette.accent200.main,
            color: palette.accent400.contrastText,
          },
        },
        text: {
          borderRadius: 0,
          textTransform: 'uppercase',
        },
        textPrimary: {},
        textSecondary: {},
        sizeSmall: {
          fontSize: '0.93125rem',
        },
        label: {
          lineHeight: '1.08em',
        },
      },
      MuiIcon: {
        colorAction: {
          color: palette.grey500,
        },
      },
      MuiIconButton: {
        root: {
          color: palette.grey500,
        },
      },
      MuiToggleButton: {
        root: {
          backgroundColor: palette.grey100,
        },
        selected: {
          color: palette.grey500,
          backgroundColor: 'transparent',
          opacity: 1,
          '&:after': {
            backgroundColor: palette.paper,
          },
        },
      },
      MuiTouchRipple: {
        root: {
          color: palette.paper,
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: `1px solid ${palette.grey200}`,
        },
        head: {
          fontSize: '0.85rem',
        },
        paddingDense: {
          [breakpoints.down('xs')]: {
            padding: `${UNIT / 3}px ${UNIT}px`,
            paddingRight: `${UNIT}px !important`,
          },
        },
      },
      MuiTab: {
        label: {
          fontWeight: 'bold',
        },
        root: {
          maxWidth: 'initial',
        },
        fullWidth: {
          flexShrink: 0,
        },
      },
      MuiTableRow: {
        head: {
          height: UNIT * 3,
        },
      },
      MuiTypography: {
        root: {
          '& a': {
            color: palette.grey500,
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
              color: palette.grey400,
              textDecoration: 'underline',
            },
          },
        },
      },
      MuiExpansionPanel: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${palette.grey200}`,
          '&:first-child': {
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
          },
          '&:last-child': {
            borderBottomLeftRadius: '0.5rem',
            borderBottomRightRadius: '0.5rem',
          },
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
        },

        expanded: {
          margin: 'auto',
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          [breakpoints.down('xs')]: {
            padding: `${UNIT / 2}px ${UNIT}px ${UNIT}px`,
          },
        },
      },
      MuiExpansionPanelSummary: {
        expandIcon: {
          [breakpoints.down('xs')]: {
            right: 0,
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '0.85rem',
        },
      },
    },
  }
}

export { UNIT }
